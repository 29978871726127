import React from 'react'

const CheckboxCheckedIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="white" />
            <path d="M5.66675 8.00016L7.33341 9.66683L10.6667 6.3335" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default CheckboxCheckedIcon