import { Box, styled } from "@mui/material";

export const StationsStyled = styled(Box)`
    .stationCard{
        .stationCardImg{
            width: 100%;
            margin: 0 0 13px;
            line-height: 0;
            position: relative;
            img{
                width: 100%;
                height: 210px;
                object-fit: cover;
            }
            .redeemedOverlay{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(NARS_FA24_ExplicitLipstick_SocialCrop_Campaign_Camila_DirtyTalk_GLBL_9x16_2.png);
                border: 1px solid #FFFFFF;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                p{
                    font-size: 16px;
                    font-family: 'helveticabold';
                    color: var(--white);
                    text-transform: uppercase;
                }
            }
        }
        h5{
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
        }
    }
`