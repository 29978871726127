import { createBrowserRouter, Navigate } from "react-router-dom";
import Register from "../pages/Register";
import FounderQuote from "../pages/FounderQuote";
import Stations from "../pages/Stations";
import GiftRedemption from "../pages/GiftRedemption";


const ProtectedRoute = ({ children }) => {
    const userId = localStorage.getItem('id');
    return userId ? children : <Navigate to="/register" />;
};

export const router = createBrowserRouter([
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/founder-quote",
        element: (
            <ProtectedRoute>
                <FounderQuote />
            </ProtectedRoute>
        ),
    },
    {
        path: "/stations",
        element: (
            <ProtectedRoute>
                <Stations />
            </ProtectedRoute>
        ),
    },
    {
        path: "/gift-redemption",
        element: (
            <ProtectedRoute>
                <GiftRedemption />
            </ProtectedRoute>
        ),
    },

    {
        path: "/",
        element: <Navigate to="/stations" replace={true} />,
    },
    {
        path: "*",
        element: <Navigate to="/stations" />,
    }

]);