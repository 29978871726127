import { Box, styled } from "@mui/material";

export const GiftRedemptionStyled = styled(Box)`
    .congratulationsBox{
        padding: 16px;
        background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(10px);
        margin-bottom: 18px;
        text-align: center;

        h1{
            font-size: 24px;
            margin-bottom: 12px;
            color: var(--white);
            font-family: 'helveticabold';
        }

        p{
            font-size: 16px;
        }
    }
`