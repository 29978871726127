import { Box } from '@mui/material'
import React from 'react'
import { asset } from '../json/asset'
import Header from './Header'


const Wrapper = ({children}) => {
  return (
    <Box className="body_content">
        <Box className="body_background">
            <img src={asset.bodyBackground} alt="" />
        </Box>

        <Box className="body_children">
          <Header />
          {children}
        </Box>
    </Box>
  )
}

export default Wrapper