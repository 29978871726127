import { Box } from '@mui/material'
import React from 'react'
import { CustomLoaderStyled } from '../StyledComponents/CustomLoaderStyled'


const Loader = () => {
  return (
    <CustomLoaderStyled>
            <Box className="customLoader" />
    </CustomLoaderStyled>
  )
}

export default Loader