import React, { useEffect, useState } from 'react'
import Wrapper from '../layout/Wrapper'
import { Box, Container, Grid, Typography } from '@mui/material'
import { StationsStyled } from '../StyledComponents/StationsStyled'
import { asset } from '../json/asset';
import LoveLetterModal from '../modals/LoveLetterModal';
import FireplaceModal from '../modals/FireplaceModal';
import PhoneBoothModal from '../modals/PhoneBoothModal';
import DiningHallModal from '../modals/DiningHallModal';
import { getUser } from '../service/firebase';
import AlreadyRedeemedModal from '../modals/AlreadyRedeemedModal';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
const Stations = () => {
    const navigate = useNavigate();
    const fetchUserDetails = async () => {
        const userId = localStorage.getItem('id');
        if (userId) {
            const resp = await getUser(userId);
            if (resp !== 'no_document' && resp !== 'error') {
                setUserDetails(resp);
                let redemptionCount = 0;
                if (resp.love_letter) {
                    redemptionCount++;
                }
                if (resp.fireplace) {
                    redemptionCount++;
                }
                if (resp.phone_booth) {
                    redemptionCount++;
                }
                if (resp.dining_hall) {
                    redemptionCount++;
                }
                if (redemptionCount === 4 && !resp.gift) {
                    navigate('/gift-redemption')
                }
            } else {
                localStorage.clear();
                navigate('/register');
            }
        }
    };
    const [userDetails, setUserDetails] = useState(null);
    useEffect(() => {
        fetchUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // handle opening and closing of Already redeemed modal
    const [openAlreadyRedeemedModal, setOpenAlreadyRedeemedModal] = useState(false);
    const [alreadyRedeemedModalTitle, setAlreadyRedeemedModalTitle] = useState('');
    const handelOpenAlreadyRedeemedModal = () => {
        setOpenAlreadyRedeemedModal(true)
    }
    const handelCloseAlreadyRedeemedModal = () => {
        setOpenAlreadyRedeemedModal(false)
    }


    // handle opening and closing of love letter modal
    const [openLoveLetterModal, setOpenLoveLetterModal] = useState(false);
    const handelOpenLoveLetterModal = () => {
        if (userDetails.love_letter) {
            handelOpenAlreadyRedeemedModal();
            setAlreadyRedeemedModalTitle('Love Letter');
            return;
        }
        setOpenLoveLetterModal(true)
    }
    const handelCloseLoveLetterModal = (status) => {
        if (status === 'success') {
            fetchUserDetails();
        }
        setOpenLoveLetterModal(false);
    }

    // handle opening and closing of fireplace modal
    const [openFireplaceModal, setOpenFireplaceModal] = useState(false);
    const handelOpenFireplaceModal = () => {
        if (userDetails.fireplace) {
            handelOpenAlreadyRedeemedModal();
            setAlreadyRedeemedModalTitle('Fireplace');
            return;
        }
        setOpenFireplaceModal(true)
    }
    const handelCloseFireplaceModal = (status) => {
        if (status === 'success') {
            fetchUserDetails();
        }
        setOpenFireplaceModal(false)
    }

    // handle opening and closing of phone booth modal
    const [openPhoneBoothModal, setOpenPhoneBoothModal] = useState(false);
    const handelOpenPhoneBoothModal = () => {
        if (userDetails.phone_booth) {
            handelOpenAlreadyRedeemedModal();
            setAlreadyRedeemedModalTitle('Phone Booth');
            return;
        }
        setOpenPhoneBoothModal(true)
    }
    const handelClosePhoneBoothModal = (status) => {
        if (status === 'success') {
            fetchUserDetails();
        }
        setOpenPhoneBoothModal(false)
    }

    // handle opening and closing of phone booth modal
    const [openDiningHallModal, setOpenDiningHallModal] = useState(false);
    const handelOpenDiningHallModal = () => {
        if (userDetails.dining_hall) {
            handelOpenAlreadyRedeemedModal();
            setAlreadyRedeemedModalTitle('DIning Hall');
            return;
        }
        setOpenDiningHallModal(true)
    }
    const handelCloseDiningHallModal = (status) => {
        if (status === 'success') {
            fetchUserDetails();
        }
        setOpenDiningHallModal(false)
    }


    return (
        <Wrapper>
            {!userDetails ? <Loader></Loader>
                :
                <>
                    <Container maxWidth="false">
                        <StationsStyled>
                            <Grid container columnSpacing={2} rowSpacing={3.4}>
                                <Grid item xs={6}>
                                    <Box className="stationCard" onClick={handelOpenLoveLetterModal}>
                                        <Box className="stationCardImg">
                                            <img src={asset.loveLetter} alt="" />
                                            {
                                                userDetails.love_letter ?
                                                    <Box className="redeemedOverlay">
                                                        <Typography variant='body1'>redeemed</Typography>
                                                    </Box> : null
                                            }
                                        </Box>
                                        <Typography variant='h5'>
                                            Love letter
                                        </Typography>

                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box className="stationCard" onClick={handelOpenFireplaceModal}>
                                        <Box className="stationCardImg">
                                            <img src={asset.fireplace} alt="" />
                                            {
                                                userDetails.fireplace ?
                                                    <Box className="redeemedOverlay">
                                                        <Typography variant='body1'>redeemed</Typography>
                                                    </Box> : null
                                            }
                                        </Box>
                                        <Typography variant='h5'>
                                            Fireplace
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box className="stationCard" onClick={handelOpenPhoneBoothModal}>
                                        <Box className="stationCardImg">
                                            <img src={asset.photobooth} alt="" />
                                            {
                                                userDetails.phone_booth ?
                                                    <Box className="redeemedOverlay">
                                                        <Typography variant='body1'>redeemed</Typography>
                                                    </Box> : null
                                            }
                                        </Box>
                                        <Typography variant='h5'>
                                            PHONE BOOTH
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box className="stationCard" onClick={handelOpenDiningHallModal}>
                                        <Box className="stationCardImg">
                                            <img src={asset.diningHall} alt="" />
                                            {
                                                userDetails.dining_hall ?
                                                    <Box className="redeemedOverlay">
                                                        <Typography variant='body1'>redeemed</Typography>
                                                    </Box> : null
                                            }
                                        </Box>
                                        <Typography variant='h5'>
                                            DINING HALL
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </StationsStyled>
                    </Container>

                    {/* love letter modal */}
                    <AlreadyRedeemedModal showModal={openAlreadyRedeemedModal} closeModal={handelCloseAlreadyRedeemedModal} title={alreadyRedeemedModalTitle} />

                    {/* love letter modal */}
                    <LoveLetterModal showModal={openLoveLetterModal} closeModal={handelCloseLoveLetterModal} />

                    {/* Fireplace Modal */}
                    <FireplaceModal showModal={openFireplaceModal} closeModal={handelCloseFireplaceModal} />

                    {/* Phone Booth Modal */}
                    <PhoneBoothModal showModal={openPhoneBoothModal} closeModal={handelClosePhoneBoothModal} />

                    {/* Dining Hall  Modal */}
                    <DiningHallModal showModal={openDiningHallModal} closeModal={handelCloseDiningHallModal} />
                </>
            }
        </Wrapper>
    )
}

export default Stations