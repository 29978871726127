import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: ["helveticalight", "sans-serif"].join(","),
        fontSize: 16,
    },
    palette: {
        primary: {
            main: "#ffffff",
            
        },
    },
  });

  export default theme;