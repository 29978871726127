import { Box, styled } from "@mui/material";

export const HeaderStyled = styled(Box)`
.header_content{
    text-align: center;
    padding-bottom: 30px;
    .logoLink{
        margin-bottom: 7px;
    }
    p{
        font-size: 16px;
    }
}
`