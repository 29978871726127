import React, { useState } from 'react'
import Wrapper from '../layout/Wrapper'
import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, TextField, Typography } from '@mui/material'
import { RegisterStyled } from '../StyledComponents/RegisterStyled'
import { asset } from '../json/asset'
import CheckboxUnCheckedIcon from '../ui/icons/CheckboxUnCheckedIcon'
import CheckboxCheckedIcon from '../ui/icons/CheckboxCheckedIcon'
import { Link, useNavigate } from 'react-router-dom';
import { addUser, addToSheet } from '../service/firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    consent: false,
  });
  const [submitClicked, setSubmitClicked] = useState(false);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [id]: type === 'checkbox' ? checked : value,
    });
  };
  // validate email
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handleRegister = async () => {
    if (!formValues.firstName) {
      toast.error('Please enter your first name.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!formValues.lastName) {
      toast.error('Please enter your last name.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!formValues.phoneNumber) {
      toast.error('Please enter your phone Number.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!formValues.email) {
      toast.error('Please enter your email.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!validateEmail(formValues.email)) {
      toast.error('Please enter a valid email.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!formValues.consent) {
      toast.error('Please agree to Privacy Policy before registation.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setSubmitClicked(true);
    const resp = await addUser(formValues.firstName, formValues.lastName, formValues.email, formValues.phoneNumber);
    // eslint-disable-next-line no-unused-vars
    if (resp !== 'email_exists') {
      const respSheet = await addToSheet(formValues.firstName, formValues.lastName, formValues.email, formValues.phoneNumber);
    }
    if (resp === 'email_exists') {
      toast.warning(`Email has been registered, Successfully retrieved your account details.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSubmitClicked(false);
      navigate("/founder-quote");
      return;
    }
    if (resp === 'error') {
      toast.error(`We are facing some internal server error, Please try again later.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSubmitClicked(false);
      return;
    }
    if (resp === 'success') {
      toast.success(`Successfully registered.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setFormValues({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        consent: false,
      });
      setSubmitClicked(false);
    }
    navigate("/founder-quote");
  };
  return (
    <Wrapper>
      <Container maxWidth="false">
        <RegisterStyled>
          <form>
            <Box className="inputGroup">
              <label htmlFor="firstName">FIRST NAME</label>
              <TextField id="firstName" variant="outlined" placeholder='Enter your first name' fullWidth
                value={formValues.firstName}
                onChange={handleChange} />
            </Box>
            <Box className="inputGroup">
              <label htmlFor="lastName">LAST NAME</label>
              <TextField id="lastName" variant="outlined" placeholder='Enter your last name' fullWidth
                value={formValues.lastName}
                onChange={handleChange} />
            </Box>

            <Box className="inputGroup">
              <label htmlFor="phoneNumber">PHONE NUMBER</label>
              <Box className="phoneInput_wrap">
                <TextField id="phoneNumber" variant="outlined" fullWidth type='number' placeholder='Enter your phone number'
                  value={formValues.phoneNumber}
                  onChange={handleChange} />
                <Box className="phoneInput_icon">
                  <img src={asset.malaysiaFlag} alt="" />
                  <Typography variant='caption'>+60</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="inputGroup">
              <label htmlFor="email">EMAIL</label>
              <TextField id="email" variant="outlined" placeholder='Enter your email address' fullWidth
                value={formValues.email}
                onChange={handleChange} />
            </Box>

            <Box className="checkboxGroup">
              <FormControlLabel control={<Checkbox id="consent" icon={<CheckboxUnCheckedIcon />} checkedIcon={<CheckboxCheckedIcon />} checked={formValues.consent} name="consent" onChange={handleChange} />} label={
                <>
                  By completing and submitting this customer profile form, you are consent to our <Link target='_blank' to="https://www.narscosmetics.my/privacy-notice">PDPA</Link>.
                </>
              } />
            </Box>

            <Box className="registerBtn">
              {
                submitClicked ?
                  <Button className='cmnBtn disbaledWhite' disabled>
                    <CircularProgress style={{ color: "#000" }} size={18} />
                  </Button> :
                  <Button className='cmnBtn' onClick={handleRegister} disabled={submitClicked}>
                    REGISTER
                  </Button>
              }
            </Box>
          </form>
        </RegisterStyled>
      </Container>

    </Wrapper>
  )
}

export default Register