import moment from 'moment';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyDojg7KeYGnQHB1NBsx2NIqjPpW9CkQLu0",
    authDomain: "nars-79f14.firebaseapp.com",
    projectId: "nars-79f14",
    storageBucket: "nars-79f14.appspot.com",
    messagingSenderId: "204403526362",
    appId: "1:204403526362:web:e69c510f0a1784d59973e3",
    measurementId: "G-W7YF1T1RL9"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const Sheet_Url = 'https://script.google.com/macros/s/AKfycbxSxVGpaB2l3fHLl3ayGy6-rTbgENwAxwijDah9xoYRU_LujtwOIjT1r6fzH7BZ_ojlEw/exec';

export async function addUser(firstName, lastName, email, phone) {
    const usersRef = collection(db, "users");

    // Check if the email already exists
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        // User exists, return the user's document data along with the ID
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        // return {
        //     status: 'email_exists',
        //     id: userDoc.id,
        //     data: userData
        // };
        localStorage.setItem('id', userDoc.id);
        localStorage.setItem('email', userData.email);
        return 'email_exists';
    }

    // Add the new user
    try {
        const docRef = await addDoc(usersRef, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            love_letter: false,
            fireplace: false,
            phone_booth: false,
            dining_hall: false,
            gift: false
        });
        localStorage.setItem('id', docRef.id);
        localStorage.setItem('email', email);
        return 'success'
    } catch (e) {
        return 'error'
    }
}
export async function updateUser(userId, updatedFields) {
    const userRef = doc(db, "users", userId);

    try {
        await updateDoc(userRef, updatedFields);
        return 'success';
    } catch (e) {
        return 'error';
    }
}
export async function getUser(userId) {
    const userRef = doc(db, "users", userId);

    try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return 'no_document';
        }
    } catch (e) {
        return 'error';
    }
}

export async function addToSheet(firstName, lastName, email, phone) {
    const data = new FormData();
    data.append('FirstName', firstName);
    data.append('LastName', lastName);
    data.append('PhoneNumber', phone);
    data.append('Email', email);
    data.append('RegisterTime', moment().format('MMMM Do YYYY, h:mm:ss a'));
    data.append('LoveLetter', false);
    data.append('Fireplace', false);
    data.append('PhoneBooth', false);
    data.append('DiningHall', false);
    data.append('Gift', false);
    data.append('action', 'add');
    try {
        await axios.post(Sheet_Url, data);
        return 'success'
    } catch (error) {
        return 'error';
    }
}
export async function editSheet(email, updateColumns) {
    const data = new FormData();
    Object.keys(updateColumns).forEach(key => data.append(key, updateColumns[key]));
    data.append('action', 'update');
    data.append('Email', email);

    try {
        await axios.post(Sheet_Url, data);
        return 'success'
    } catch (error) {
        return 'error';
    }
}