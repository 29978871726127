import { Box, styled } from "@mui/material";

export const RegisterStyled = styled(Box)`
    .inputGroup{
        margin-bottom: 24px;
        label{
            display: block;
            margin-bottom: 7px;
            color: var(--colorE4E7EC);
            font-size: 14px;
        }

        .phoneInput_wrap{
            position: relative;
            .MuiInputBase-input{
                padding-left: 80px;
            }
            .phoneInput_icon{
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                padding-right: 10px;
                border-right: 1px solid #98A2B3;

                span{
                    line-height: 1;
                    font-family: "Inter", sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    padding-left: 2px;
                }
            }
        }
    }

    .checkboxGroup{
        margin-bottom: 24px;
        .MuiFormControlLabel-root{
            .MuiTypography-root{
                font-size: 12px;
                color: var(--color98A2B3);

                a{
                    color: var(--white);
                }
            }
        }
    }

`