import React, { useState } from 'react'
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import ModalCloseIcon from '../ui/icons/ModalCloseIcon';
import { DialogStyled } from '../StyledComponents/DialogStyled';
import { toast } from 'react-toastify';
import { updateUser, editSheet } from '../service/firebase';
const PhoneBoothModal = ({ showModal, closeModal }) => {
    const [modalInputValue, setModalInputValue] = useState("")

    const handleModalInputChnage = (event) => {
        setModalInputValue(event.target.value)
    }
    const [submitClicked, setSubmitClicked] = useState(false);
    const phoneBoothRedeemCode = process.env.REACT_APP_PHONE_BOOTH;
    const handelModalSubmit = async () => {
        setSubmitClicked(true);
        if (modalInputValue.localeCompare(phoneBoothRedeemCode, undefined, { sensitivity: 'base' }) === 0) {
            const userId = localStorage.getItem('id');
            const userEmail = localStorage.getItem('email');
            const resp = await updateUser(userId, { phone_booth: true });
            editSheet(userEmail, { PhoneBooth: true }).then();
            if (resp === 'success') {
                toast.success(`Redeemed successfully.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                closeModal('success');
                setModalInputValue("");
            } else {
                toast.success(`Internal server Error, Please try again later.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                closeModal('error');
                setModalInputValue("");
            }
        } else {
            console.log("Failed");
            toast.error(`Invalid redemption code!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setSubmitClicked(false);
    }
    return (

        <React.Fragment>

            <DialogStyled
                open={showModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
                fullWidth={true}
            >
                <Box className="dialog_inner">

                    <Button className='dialogCloseBtn' onClick={closeModal}>
                        <ModalCloseIcon />
                    </Button>
                    <Box className="dialogTop">
                        <Typography variant='h5'>PHONE BOOTH</Typography>
                        <Typography variant='body1'>
                            "Listen to the messages whispered by our muses"
                        </Typography>
                    </Box>

                    <Box className="dialogBot">
                        <TextField variant="outlined" placeholder='Enter your redemption code' fullWidth
                            onChange={handleModalInputChnage}
                        />

                        <Box className="dialogBtn">
                            {
                                submitClicked ?
                                    <Button className='cmnBtn disbaledWhite' disabled>
                                        <CircularProgress style={{ color: "#000" }} size={18} />
                                    </Button> :
                                    <Button className='cmnBtn' onClick={handelModalSubmit}>REDEEM</Button>
                            }
                        </Box>
                    </Box>
                </Box>



            </DialogStyled>
        </React.Fragment>

    )
}

export default PhoneBoothModal


