import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import ModalCloseIcon from '../ui/icons/ModalCloseIcon';
import { DialogStyled } from '../StyledComponents/DialogStyled';

const AlreadyRedeemedModal = ({ showModal, closeModal, title }) => {

    return (
        <React.Fragment>
            <DialogStyled
                open={showModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
                fullWidth={true}
            >
                <Box className="dialog_inner alreadyRedeemed">
                    <Button className='dialogCloseBtn' onClick={closeModal}>
                        <ModalCloseIcon />
                    </Button>
                    <Box className="dialogTop">
                        <Typography variant='h5'>{title}</Typography>                        
                    </Box>
                    <Typography variant='body1'>You have redeemed your code</Typography>

                    <Box className="dialogBot">
                           

                            <Box className="dialogBtn">
                                <Button className='cmnBtn' onClick={closeModal}>Back</Button>
                            </Box>
                        </Box>
                </Box>

            </DialogStyled>

        </React.Fragment>

    )
}

export default AlreadyRedeemedModal