import { Box, styled } from "@mui/material";

export const FounderQuoteStyled = styled(Box)`
 
    .quoteArea{
        text-align: center;
        margin-bottom: 24px;
        height: 100%;

        p{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        span{
            &.founderInfo{
                font-style: italic;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

`