import React from 'react'
import { FounderQuoteStyled } from '../StyledComponents/FounderQuoteStyled'
import { Box, Button, Container, Typography } from '@mui/material'
import Wrapper from '../layout/Wrapper'
import { useNavigate } from 'react-router-dom'

const FounderQuote = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Container maxWidth="false">
        <FounderQuoteStyled>
          <Box className="verficallyCenteredSection">
            <Box className="quoteArea">
              <Typography variant='body1'>
                “Lipstick has always been about confidence for me. Here’s to thirty years of character-building color, and the Explicit content that’s still to come.”
              </Typography>
              <Typography variant='caption' className='founderInfo'>
                - François Nars, Founder & Creative Director
              </Typography>
            </Box>
            <Box className="founderQuoteSubmitBtn">
              <Button className='cmnBtn' onClick={() => navigate('/stations')}>NEXT</Button>
            </Box>
          </Box>
        </FounderQuoteStyled>
      </Container>
    </Wrapper>
  )
}

export default FounderQuote