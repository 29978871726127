/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Wrapper from '../layout/Wrapper'
import { Box, Button, Container, Typography } from '@mui/material'
import { GiftRedemptionStyled } from '../StyledComponents/GiftRedemptionStyled'
import GiftRedemptionModal from '../modals/GiftRedemptionModal'
import { getUser } from '../service/firebase';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'
const GiftRedemption = () => {
    const navigate = useNavigate();
    const fetchUserDetails = async () => {
        const userId = localStorage.getItem('id');
        if (userId) {
            const resp = await getUser(userId);
            if (resp !== 'no_document' && resp !== 'error') {
                setUserDetails(resp);
            } else {
                localStorage.clear();
                navigate('/register');
            }
        }
    };
    const [userDetails, setUserDetails] = useState(null);
    useEffect(() => {
        fetchUserDetails();         
    }, []);

    // handle opening and closing of Gift Redemption modal
    const [openGiftRedemptionModal, setOpenGiftRedemptionModal] = useState(false);
    const handelOpenGiftRedemptionModal = () => {
        setOpenGiftRedemptionModal(true)
    }
    const handelCloseGiftRedemptionModal = (status) => {
        if (status === 'success') {
            fetchUserDetails();
        }
        setOpenGiftRedemptionModal(false)
    }


    return (
        <Wrapper>
            {
                !userDetails ? <Loader></Loader>
                    :
                    <>
                        <Container maxWidth="false">
                            <GiftRedemptionStyled>
                                <Box className="verficallyCenteredSection">
                                    <Box className="congratulationsBox">
                                        <Typography variant='h1'>
                                            CONGRATULATIONS!
                                        </Typography>
                                        {
                                            userDetails.gift ?
                                                <Typography variant='body1'>
                                                    You have already redeemed a NARS samples at the registration counter.
                                                </Typography>
                                                :
                                                <Typography variant='body1'>
                                                    You can now redeem a NARS samples at the registration counter.
                                                </Typography>
                                        }

                                    </Box>
                                    {
                                        !userDetails.gift ?
                                            <Box className="congratulationsBtn">
                                                <Button className='cmnBtn' onClick={handelOpenGiftRedemptionModal}>GIFT REDEMPTION</Button>
                                            </Box>
                                            : null
                                    }
                                </Box>
                            </GiftRedemptionStyled>
                        </Container>
                        <GiftRedemptionModal showModal={openGiftRedemptionModal} closeModal={handelCloseGiftRedemptionModal} />
                    </>
            }

        </Wrapper>
    )
}

export default GiftRedemption