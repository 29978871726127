import { Dialog, styled } from "@mui/material";

export const DialogStyled = styled(Dialog)`
.MuiDialog-paper{
    background: #000000;
    border: 1px solid #98A2B3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px 16px;
    border-radius: 0;
    position: relative;
    max-width: calc(100% - 16px);
    width: calc(100% - 16px);
    margin-left: 16px;
    margin-right: 16px;

    .dialog_inner{
        position: relative;
        text-align: center;

        &.alreadyRedeemed{
            p{
                color: var(--white);
                font-size: 14px;
            }

            .dialogTop{
                margin-bottom: 12px;
                padding-bottom: 0;
            }

            .dialogBot{
                .dialogBtn{
                    padding-top: 16px;
                }
            }
        }
        .dialogCloseBtn {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            padding: 0;
            min-width: initial;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .dialogTop{
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--color344054);

            &.noBorder{
                border-bottom: 0;
                padding-bottom: 0;
            }
            h5{
                font-size: 16px;
                color: var(--white);
                line-height: 24px;
                text-transform: uppercase;
                margin-bottom: 12px;
            }
            p{
                color: var(--white);
                font-size: 14px;
            }
        }

        .dialogBot{
            .MuiInputBase-input{
                text-align: center;
            }
            .dialogBtn{
                padding-top: 12px;
            }
        }
    }
}
`